import React, { Component } from "react"
import "./styles/team.scss";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export const ResponsiveImage = ({ image, className, ...otherProps }) => (
  <picture>
    <source media="(max-width: 800px)" srcSet={`${image.url}`} />
    <img alt={image.alt ? image.alt : ''} {...otherProps} className={className} src={`${image.url}`} />
  </picture>
)

export const CrewMember = ({ member }) => {
  console.log(member.description);
  return (<div className={"member"} >
    <div className={"memberCard"}>
      {member.profilePhoto ? <ResponsiveImage image={member.profilePhoto.file} className={"memberImage"} /> : null}
      <div className={"memberContent"}>
        <div className={"memberDescription"}>{documentToReactComponents(member.description.json)}</div>

        <div className={"icons"}>
          {member.linkedin ? <a className={"icon"} target="_blank" href={member.linkedin}>
            <img src='https://lh3.googleusercontent.com/70MoJCGsPfrV7EARZK_EQU-p8H4XRHRt_cH2MhtmbEdB5MsyBt9aJexQMPmUuL_4ORelU177S8OzfkkR8_shQvsB' />
          </a> : null}
          {member.twitter ? <a className={"icon"} target="_blank" href={member.twitter}>
            <img src='https://lh3.googleusercontent.com/piWrc7iSorzcALgZaeB_7KyLxS_xUHZPFoz8Ee0zwbaLw9oalR1f2KzTcfvlR7Vl1g6cZbiEgbamS3CFJTFZJFQ' />
          </a> : null}
          {member.github ? <a className={"icon"} target="_blank" href={member.github}>
            <img src='https://lh3.googleusercontent.com/O0FIaLpGQU1oQTk44ExvQO9W7Wk_dUMq0ZGTaTNLsj_HPq7XYsvIkq--Lx_1O3nno8EpbYUv_Rud5dFr3F3gHbI' />
          </a> : null}
          {member.instagram ? <a className={"icon"} target="_blank" href={member.instagram}>
            <img src='https://lh3.googleusercontent.com/1AwkWJS0zD4QOFjBx5M5PWjZaGLzNOS43jEdonOJrpvUP2IWpN2AQU2ByhVn6iVCBsy_VlVPWwBkLq5KJZN7sLI' />
          </a> : null}
        </div>
      </div>
    </div>
    <p className={'memberName'}>{member.name}</p>
    <p>{member.department} ({member.responsibleFor})</p>
  </div >
  )
}

export class RenderOnMount extends Component {
  constructor(props) {
    super(props)
    this.state = { render: false }
  }

  componentDidMount() {
    this.setState({ render: true })
  }

  render() {
    const { children } = this.props
    if (this.state.render) {
      return children
    } else {
      return <> </>
    }
  }
}
